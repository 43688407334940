import fetch from 'isomorphic-fetch'
import _ from 'lodash'
import { Observable } from 'rxjs/Observable'
import 'rxjs/add/observable/fromPromise'
import 'rxjs/add/observable/of'
import 'rxjs/add/observable/range'
import 'rxjs/add/observable/empty'
import 'rxjs/add/operator/concatMap'
import 'rxjs/add/operator/mergeMap'
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/reduce'
import 'rxjs/add/operator/retry'
import 'rxjs/add/operator/filter'
import 'rxjs/add/operator/catch'
import 'rxjs/add/operator/takeUntil'
import 'rxjs/add/operator/takeWhile'
import { viewTypes } from 'widget/dining/utils/constantTypes'
import {
  TRY_GET_INITIAL_AVAILABILITY,
  GET_INITIAL_AVAILABILITY_SUCCESS,
  TRY_GET_MULTI_VENUE_AVAILABILITY,
  GET_MULTI_VENUE_AVAILABILITY_SUCCESS,
  GET_ADDITIONAL_AVAILABILITY_SUCCESS,
  REVERT_STAGE,
} from '../../actions/ActionTypes'
import {
  getInitialAvailabilitySuccess,
  getMultiVenueAvailabilitySuccess,
  getAdditionalAvailabilitySuccess,
  getAdditionalAvailabilityComplete,
  postReleaseReservationHold,
} from '../../actions/services'
import { selectQueryMoment } from '../../SearchResults/selectors'
import { SEARCHRESULTS_DISPLAY_LENGTH, ALL_LOCATIONS } from '../../utils/convertData'
import { getAvailabilityUrlWithParams } from '../../utils/epicHelpers'
import { transformInitialDataToState, transformVenueDataToState, transformAdditionalDataToState } from '../transformData'

const DATE_RANGE = 3
const NUM_REQUESTS = 10

const getJson = url => Observable.fromPromise(fetch(url)).concatMap(response => Observable.fromPromise(response.json()))

const getSingleAvailabilitylUrl = (state, queryMoment, numDays) => {
  const origin = state.widgetSettings.baseUrl
  const selectedVenue = state.search.get('selectedVenue')
  const venueKey = selectedVenue === ALL_LOCATIONS ? state.venueInfo.urlKey : selectedVenue
  const partySize = state.search.get('partySize')
  const pickedDuration = state.search.get('enableDurationPicker') ? state.search.get('duration') : null
  const timeHalo = state.search.get('resultsWithinTimeHalo')
  const isModifyResMode = state.modifyReservation.get('isModifyResMode')
  const actualId = state.modifyReservation.get('actualId')
  const experienceId = state.experience.get('id')
  const { selectedLanguage } = state.languages

  return getAvailabilityUrlWithParams(
    origin,
    venueKey,
    partySize,
    queryMoment,
    timeHalo,
    numDays,
    isModifyResMode,
    actualId,
    experienceId,
    pickedDuration,
    selectedLanguage
  )
}

const getVenueUrlObject = (state, queryMoment, filterMainVenue) => {
  const origin = state.widgetSettings.baseUrl
  const partySize = state.search.get('partySize')
  const pickedDuration = state.search.get('enableDurationPicker') ? state.search.get('duration') : null
  const timeHalo = state.search.get('resultsWithinTimeHalo')
  const numDays = 1
  const venueMap = state.search.get('venueMap').toObject()
  const isModifyResMode = state.modifyReservation.get('isModifyResMode')
  const actualId = state.modifyReservation.get('actualId')
  const experienceId = state.experience.get('id')
  const { selectedLanguage } = state.languages

  return _.reduce(
    venueMap,
    (urls, venueName, venueKey) => {
      if (venueKey === ALL_LOCATIONS) {
        return urls
      }
      if (filterMainVenue && venueKey === state.search.get('selectedVenue')) {
        return urls
      }

      if (!state.venueEntities[venueKey].isMultiVenueReservationEnabled) {
        return urls
      }

      return urls.concat({
        venueKey,
        availabilityUrl: getAvailabilityUrlWithParams(
          origin,
          venueKey,
          partySize,
          queryMoment,
          timeHalo,
          numDays,
          isModifyResMode,
          actualId,
          experienceId,
          pickedDuration,
          selectedLanguage
        ),
      })
    },
    []
  )
}

export const fetchInitialAvailability = (action$, store) =>
  action$
    .ofType(TRY_GET_INITIAL_AVAILABILITY)
    .concatMap(() => {
      const state = store.getState()
      const venueKey = state.search.get('selectedVenue')
      const queryMoment = selectQueryMoment(state)
      const url = getSingleAvailabilitylUrl(state, queryMoment, 1)
      return getJson(url)
        .retry(3)
        .map(response => {
          const responseAvailability = {
            [venueKey]: response.data.availability,
          }
          return transformInitialDataToState(responseAvailability, queryMoment, venueKey, state.venueInfo.startOfDayTime)
        })
    })
    .map(data => getInitialAvailabilitySuccess(data))

export const fetchMultiVenueAvailability = (action$, store) =>
  action$
    .ofType(GET_INITIAL_AVAILABILITY_SUCCESS, TRY_GET_MULTI_VENUE_AVAILABILITY)
    .filter(() => !store.getState().experience.get('isExperienceMode'))
    .mergeMap(action => {
      const state = store.getState()
      const queryMoment = selectQueryMoment(state)
      const venueUrlObjs = getVenueUrlObject(state, queryMoment, action.type === GET_INITIAL_AVAILABILITY_SUCCESS)
      return Observable.of(...venueUrlObjs)
        .mergeMap(urlObj =>
          getJson(urlObj.availabilityUrl)
            .retry(3)
            .catch(() => Observable.empty)
            .map(response => ({
              [urlObj.venueKey]: response.data.availability,
            }))
            .map(venueData => {
              const startOfDayTime = urlObj.venueKey in state.venueEntities ? state.venueEntities[urlObj.venueKey].startOfDayTime : null
              return transformVenueDataToState(venueData, queryMoment, urlObj.venueKey, startOfDayTime)
            })
        )
        .takeUntil(action$.filter(action => action.type === REVERT_STAGE))
        .reduce((acc, curr) => acc.concat(curr), [])
    })
    .map(output => getMultiVenueAvailabilitySuccess(output))

export const fetchAdditionalAvailability = (action$, store) =>
  action$
    .filter(action => action.type === GET_MULTI_VENUE_AVAILABILITY_SUCCESS)
    .concatMap(() => {
      let state = store.getState()
      const selectedVenue = state.search.get('selectedVenue')
      const venueKey = selectedVenue === ALL_LOCATIONS ? state.venueInfo.urlKey : selectedVenue
      let queryMoment = selectQueryMoment(state).clone().add(1, 'day')
      let bookingEnd = state.search.get('bookingEnd')
      bookingEnd = bookingEnd && bookingEnd.clone().add(1, 'day')
      if (bookingEnd && queryMoment >= bookingEnd) {
        return Observable.of(getAdditionalAvailabilityComplete())
      }
      const isFetchingAdditional = state.searchResults.get('isFetchingAdditional')
      const startOfDayTime = venueKey in state.venueEntities ? state.venueEntities[venueKey].startOfDayTime : null
      if (!isFetchingAdditional) {
        return Observable.empty()
      }
      return Observable.range(1, NUM_REQUESTS)
        .concatMap(() => {
          state = store.getState()
          queryMoment = state.searchResults.get('searchDateCursor') || queryMoment
          const url = getSingleAvailabilitylUrl(state, queryMoment, DATE_RANGE)
          bookingEnd = state.search.get('bookingEnd')
          bookingEnd = bookingEnd && bookingEnd.clone().add(1, 'day').format('YYYY-MM-DD')
          return getJson(url)
            .retry(1)
            .catch(() => Observable.empty)
            .map(response => ({ [venueKey]: response.data.availability }))
            .map(venueData => transformAdditionalDataToState(venueData, queryMoment, venueKey, bookingEnd, startOfDayTime))
        })
        .takeUntil(action$.filter(action => action.type === REVERT_STAGE))
        .map(output => getAdditionalAvailabilitySuccess(output))
        .takeWhile(() => _shouldFetchAdditional(store))
    })

const _shouldFetchAdditional = store => {
  const state = store.getState()
  const shouldRetrieveMoreResults = state.searchResults.get('additionalResults').size < SEARCHRESULTS_DISPLAY_LENGTH
  const searchDateCursor = state.searchResults.get('searchDateCursor')
  let bookingEnd = state.search.get('bookingEnd')
  bookingEnd = bookingEnd && bookingEnd.clone().add(1, 'day')
  let cursorIsBeforeEndData = true
  if (searchDateCursor && bookingEnd) {
    cursorIsBeforeEndData = searchDateCursor < bookingEnd
  }
  return shouldRetrieveMoreResults && cursorIsBeforeEndData
}

export const findWhenAvailabilityComplete = (action$, store) =>
  action$
    .filter(action => action.type === GET_ADDITIONAL_AVAILABILITY_SUCCESS)
    .filter(() => !_shouldFetchAdditional(store))
    .map(() => getAdditionalAvailabilityComplete())

const _shouldReleaseReservationHold = store => {
  const state = store.getState()
  return state.ui.get('viewSequence').get(state.ui.get('stage')) === viewTypes.SEARCH_RESULTS
}

export const releaseReservationHoldOnRevertStage = (action$, store) =>
  action$
    .filter(action => action.type === REVERT_STAGE)
    .filter(() => _shouldReleaseReservationHold(store))
    .map(() => postReleaseReservationHold())
