import _ from 'lodash'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'
import Theme from 'mgr/layout/Theme'
import { BrandVmsIconS, BrandMap, BRAND_MAPPER, BRAND_MAPPER_NAMES } from 'svr/common/BrandIcon'

const DISABLED_COLOR = '#DBDCDE'

const ChannelSpecificDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const DataSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DisabledDataSectionContainer = styled.div`
  opacity: 0.24;
  filter: grayscale(100%);
`

const SingleChannelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.gutter.double};
`

const ChannelInfoContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s;

  :hover {
    opacity: 0.7;
  }
`

const SegmentedDataBarContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

const ChannelLabel = styled.h3`
  margin: 0;
  padding: ${props => props.theme.gutter.standard} 0;
  color: ${props => props.theme.color.grey};
  font-weight: 300;
  text-transform: uppercase;
`

const ChannelName = styled.span`
  padding-left: ${props => props.theme.gutter.standard};
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.color.black};
`

const DataTotal = styled.span`
  font-size: 14px;
  padding-right: ${props => props.theme.gutter.standard};
  text-align: right;
  min-width: 24px;
  flex: 1;
`

// TODO clean up all random numbers like border radius and min width segmented into one locale
// TODO polish: transitions
export const SegmentedBar = styled.div`
  min-width: 300px;
  height: 24px;
  border-radius: 4px;
  background: ${props => (props.theme.color ? props.theme.color.greyMuted : '#EBEBEB')};
  background: linear-gradient(to right, ${props => props.gradient});
`

const SingleChannelSegContainer = styled.div`
  flex: 1;
`

const ChannelTooltipBreakdown = styled.div`
  text-transform: capitalize;
  text-align: center;
`

// TODO put in utils or alongside segmented bar in component-lib
export const getSegmentGradients = (total, segments) => {
  let currentPercentage = 0

  // to get the desired effect for multiple colors, you have to put the segments starting percentage and ending percentage
  // example: 'red %0, red %20' will fill the background color from 0 to 20%
  const gradients = segments.reduce((acc, current) => {
    const percentage = (current.value / total) * 100

    acc.push(`${current.color} ${currentPercentage}%`)
    currentPercentage += percentage
    acc.push(`${current.color} ${currentPercentage}%`)
    return acc
  }, [])

  return gradients.join(',')
}

const channelTooltip = (breakdown, total) => (
  <div>
    {breakdown.map(type => (
      <ChannelTooltipBreakdown key={type.name}>
        {`${Math.round((type.value / total) * 100) || 0}% ${type.name} (${type.value})`}
      </ChannelTooltipBreakdown>
    ))}
  </div>
)

const SegmentedDataBar = ({ total = 0, segments = [], hoverElement = null }) => {
  const id = `segmented-tip-${_.uniqueId()}`

  return (
    <SegmentedDataBarContainer>
      <DataTotal>{total}</DataTotal>
      <ReactTooltip id={id}>{hoverElement}</ReactTooltip>
      <SegmentedBar data-tip data-for={id} gradient={getSegmentGradients(total, segments)} />
    </SegmentedDataBarContainer>
  )
}

const SingleChannelData = ({ isFullFeatured, icon, name, reviews, onPlatformFilterChange }) => {
  const toolTip = name === 'SevenRooms' || isFullFeatured ? channelTooltip(reviews.breakdown, reviews.total) : () => {}
  const platformFilterName = name === 'OpenTable' ? 'opentable,opentableusa' : name.toLowerCase()

  return (
    <SingleChannelContainer data-test="single-channel-container">
      <ChannelInfoContainer onClick={() => onPlatformFilterChange(platformFilterName)}>
        {icon}
        <ChannelName>{name}</ChannelName>
      </ChannelInfoContainer>
      <SingleChannelSegContainer>
        <SegmentedDataBar total={reviews.total} segments={reviews.breakdown} hoverElement={toolTip} />
      </SingleChannelSegContainer>
    </SingleChannelContainer>
  )
}

const DataSection = ({ isFullFeatured, label, channels, onPlatformFilterChange }) => {
  const Container = isFullFeatured ? DataSectionContainer : DisabledDataSectionContainer

  return (
    <Container>
      <ChannelLabel>{label}</ChannelLabel>
      {channels.map((channel, idx) => (
        <SingleChannelData
          isFullFeatured={isFullFeatured}
          key={channel.name}
          icon={channel.icon}
          name={channel.name}
          reviews={channel.reviews}
          onPlatformFilterChange={onPlatformFilterChange}
        />
      ))}
    </Container>
  )
}

class ChannelSpecificData extends React.PureComponent {
  render() {
    const { isFullFeatured, channels, theme, onPlatformFilterChange, pngSrcMap } = this.props
    const { sevenrooms } = channels
    const externalChannels = Object.keys(channels).filter(brand => brand !== 'sevenrooms')
    const color = e => (isFullFeatured ? e : DISABLED_COLOR)

    return (
      <ChannelSpecificDataContainer>
        {sevenrooms && (
          <DataSection
            label="Feedback"
            onPlatformFilterChange={isFullFeatured ? onPlatformFilterChange : () => {}}
            isFullFeatured
            channels={[
              {
                icon: <BrandVmsIconS company={BrandMap.SevenRooms} />,
                name: 'SevenRooms',
                reviews: {
                  total: sevenrooms.total,
                  breakdown: [
                    {
                      color: Theme.color.positive,
                      value: sevenrooms.positive,
                      name: 'positive',
                    },
                    {
                      color: Theme.color.neutral,
                      value: sevenrooms.neutral,
                      name: 'neutral',
                    },
                    {
                      color: Theme.color.negative,
                      value: sevenrooms.negative,
                      name: 'negative',
                    },
                  ],
                },
              },
            ]}
          />
        )}

        {externalChannels.length > 0 && (
          <DataSection
            label="Reviews"
            onPlatformFilterChange={isFullFeatured ? onPlatformFilterChange : () => {}}
            isFullFeatured={isFullFeatured}
            channels={externalChannels.map(brand => ({
              icon: <BrandVmsIconS company={BrandMap[BRAND_MAPPER[brand]]} pngSrc={pngSrcMap[brand]} />,
              name: BRAND_MAPPER_NAMES[brand] || BRAND_MAPPER[brand],
              reviews:
                brand === 'facebook'
                  ? {
                      total: channels[brand].total,
                      breakdown: [
                        {
                          color: color(Theme.color.positive),
                          value: channels[brand].positive,
                          name: 'recommend',
                        },
                        {
                          color: color(Theme.color.negative),
                          value: channels[brand].negative,
                          name: 'doesn’t recommend',
                        },
                      ],
                    }
                  : {
                      total: channels[brand].total,
                      breakdown: [
                        {
                          color: color(Theme.color.positive),
                          value: channels[brand].positive,
                          name: 'positive',
                        },
                        {
                          color: color(Theme.color.neutral),
                          value: channels[brand].neutral,
                          name: 'neutral',
                        },
                        {
                          color: color(Theme.color.negative),
                          value: channels[brand].negative,
                          name: 'negative',
                        },
                      ],
                    },
            }))}
          />
        )}
      </ChannelSpecificDataContainer>
    )
  }
}

export default ChannelSpecificData
