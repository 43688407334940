/* eslint react/prop-types: 0, no-shadow: 0 */
import _ from 'lodash'
import Radium from 'radium'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { selectCalculatedLanguageStrings, selectLanguageStrings } from 'widget/dining/selectors/languageSelectors'
import { camelCaseString } from '../../../../common/StringUtils'
import ErrorDisplay from '../../../../lib/Modal/ErrorDisplay'
import Modal from '../../../../lib/Modal/Modal'
import PromoCodePopover from '../../../../lib/Modal/PromoCodePopover'
import Spinner from '../../../../lib/Modal/Spinner'
import { CreditCardImages } from '../../../../lib/Payments/Constants'
import { saveTagGroup, changeFormField } from '../actions/forms'
import { confirmTimeSlot, dismissModal } from '../actions/navigation'
import DiningAdditionalSelection from '../components/DiningAdditionalSelection'
import NoteInput from '../components/NoteInput'
import PolicyPopover from '../components/PolicyPopover'
import { modalTypes } from '../utils/constantTypes'
import TagSelector from './TagSelector'

class ModalManager extends Component {
  constructor(props) {
    super(props)

    this.changeNoteField = this.props.changeFormField.bind(this, 'note')
    this.modalBody = null
    this.shouldFocusFirst = false
    this.focusedElBeforeOpen = null
    this.currentModalType = null
  }

  componentDidMount() {
    document.addEventListener('keydown', this._handleTabKeyDown, false)
  }

  componentDidUpdate() {
    if (this.shouldFocusFirst) {
      this._focusFirstModalEl()
      this.shouldFocusFirst = false
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleTabKeyDown, false)
  }

  _shouldShowModal = modalType => modalType && (modalType !== modalTypes.PROMO_CODE_ADDED || this.props.validPromoCode)

  _focusFirstModalEl = () => {
    const { firstElementId } = this.modalBody.type
    if (!firstElementId) {
      return
    }
    document.getElementById(firstElementId).focus()
  }

  _handleDismissModal = () => {
    this.modalBody = null
    if (this.focusedElBeforeOpen) {
      this.focusedElBeforeOpen.focus()
    }
    this.focusedElBeforeOpen = null
  }

  _handleModalCss = apply => {
    document.body.style.overflow = apply ? 'hidden' : ''
  }

  _setModalBody = modalType => {
    const shouldShowModal = this._shouldShowModal(modalType)
    if (!this.modalBody && shouldShowModal) {
      this.focusedElBeforeOpen = document.activeElement
      this.modalBody = this._modalSwitch(modalType)
      this.shouldFocusFirst = true
      this._handleModalCss(true)
      this.currentModalType = modalType
    } else if (this.modalBody && !shouldShowModal) {
      this._handleDismissModal()
      this._handleModalCss(false)
    } else if (this.modalBody && shouldShowModal) {
      if (this.currentModalType !== modalType) {
        // Handles modal replacement
        this._handleDismissModal()
        this._handleModalCss(true)
        this.focusedElBeforeOpen = document.activeElement
        this.modalBody = this._modalSwitch(modalType)
        this.shouldFocusFirst = true
      }
    }
  }

  _modalSwitch = modalType => {
    const {
      dietTagGroups,
      occasionTagGroup,
      dismissModal,
      selectHandler,
      agreeToWaitlistPolicy,
      eventWidgetPurchasePolicy,
      customCheckoutPolicy,
      venueGroupMarketingOptInPolicy,
      venueSpecificMarketingOptInPolicy,
      venueSmsMarketingOptInPolicy,
      reservationSmsOptInPolicy,
      currencyCode,
      colorPrimary,
      fontsColorSecondary,
      saveTagGroups,
      promoCodeHeaderIcon,
      promoCodeLogo,
      promoCodeLogoDisplay,
      promoCodeDetails,
      note,
      mediaUrl,
      modalMessage,
      actionText,
      actionCallback,
      colorError,
      colorModalBackground,
      fontsColorButton,
      selectedTime,
      selectedTimeFormatted,
      selectedAvailability,
      textMarketingPolicy,
      textReservationSmsPolicy,
      textCancellationPolicy,
      textCustomCheckoutPolicyHeader,
      textAdditionalSelection,
      textPerPerson,
      textPerReservation,
      textTagLabelYours,
      textTagLabelYourGuests,
      textSaveButtonLabel,
      textCancelButtonLabel,
      textSelectButtonLabel,
      upsellInventories,
      partySize,
      cost,
      fees,
      specialAttentionMessageHeader,
      specialAttentionMessageBody,
      textReservationNotesPlaceholder,
      tagTranslations,
      selectGratuityModalMessage,
      waitlistAgreePolicyModalTitle,
    } = this.props

    const {
      DIET_TAG_SELECT,
      OCCASIONS_TAG_SELECT,
      BOOKING_POLICY,
      CUSTOM_CHECKOUT_POLICY,
      DINING_ADDITIONAL_SELECTION,
      VENUE_GROUP_MARKETING_OPT_IN_POLICY,
      VENUE_SPECIFIC_MARKETING_OPT_IN_POLICY,
      VENUE_SMS_MARKETING_OPT_IN_POLICY,
      RESERVATION_SMS_OPT_IN_POLICY,
      PROMO_CODE_ADDED,
      PROMO_CODE_REMOVED,
      RESERVATION_NOTES,
      SPINNER,
      UI_DISABLED,
      ERROR_DISPLAY,
      WAITLIST_AGREE_POLICY,
      SPECIAL_ATTENTION_MESSAGE,
      SELECT_GRATUITY,
    } = modalTypes

    switch (modalType) {
      case DIET_TAG_SELECT:
        return (
          <TagSelector
            tagGroups={dietTagGroups}
            groupNames={[textTagLabelYours, textTagLabelYourGuests]}
            onCancel={dismissModal}
            onSave={saveTagGroups}
            colorPrimary={fontsColorButton}
            colorSecondary={colorPrimary}
            saveButtonLabel={textSaveButtonLabel}
            cancelButtonLabel={textCancelButtonLabel}
            tagTranslations={tagTranslations}
          />
        )
      case OCCASIONS_TAG_SELECT:
        return (
          <TagSelector
            tagGroups={occasionTagGroup}
            onCancel={dismissModal}
            onSave={saveTagGroups}
            colorPrimary={fontsColorButton}
            colorSecondary={colorPrimary}
            saveButtonLabel={textSaveButtonLabel}
            cancelButtonLabel={textCancelButtonLabel}
            tagTranslations={tagTranslations}
          />
        )
      case WAITLIST_AGREE_POLICY:
        return (
          <PolicyPopover
            policyHeader={waitlistAgreePolicyModalTitle}
            policyDetails={agreeToWaitlistPolicy}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case BOOKING_POLICY:
        return (
          <PolicyPopover
            policyHeader={textCancellationPolicy}
            policyDetails={eventWidgetPurchasePolicy}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case CUSTOM_CHECKOUT_POLICY:
        return (
          <PolicyPopover
            policyHeader={textCustomCheckoutPolicyHeader}
            policyDetails={customCheckoutPolicy}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case DINING_ADDITIONAL_SELECTION:
        return (
          <DiningAdditionalSelection
            policyHeader={textAdditionalSelection}
            policyDetails={eventWidgetPurchasePolicy}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            selectedAvailability={selectedAvailability}
            selectedTimeFormatted={selectedTimeFormatted}
            upsellInventories={upsellInventories}
            textColor={fontsColorSecondary}
            selectHandler={selectHandler}
            partySize={partySize}
            cost={cost}
            fees={fees}
            currencyCode={currencyCode}
            textPerPerson={textPerPerson}
            textPerReservation={textPerReservation}
            selectButtonLabel={textSelectButtonLabel}
          />
        )
      case VENUE_GROUP_MARKETING_OPT_IN_POLICY:
        return (
          <PolicyPopover
            policyHeader={textMarketingPolicy}
            policyDetails={venueGroupMarketingOptInPolicy}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case VENUE_SPECIFIC_MARKETING_OPT_IN_POLICY:
        return (
          <PolicyPopover
            policyHeader={textMarketingPolicy}
            policyDetails={venueSpecificMarketingOptInPolicy}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case VENUE_SMS_MARKETING_OPT_IN_POLICY:
        return (
          <PolicyPopover
            policyHeader={textMarketingPolicy}
            policyDetails={venueSmsMarketingOptInPolicy}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case RESERVATION_SMS_OPT_IN_POLICY:
        return (
          <PolicyPopover
            policyHeader={textReservationSmsPolicy}
            policyDetails={reservationSmsOptInPolicy}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case SELECT_GRATUITY:
        return (
          <ErrorDisplay
            errorMessage={selectGratuityModalMessage}
            actionText={actionText}
            dismissModal={() => dismissModal() || actionCallback()}
            errorColor={colorError}
          />
        )
      case PROMO_CODE_ADDED:
        return (
          <PromoCodePopover
            promoCodeHeaderIcon={promoCodeHeaderIcon}
            promoCodeLogo={promoCodeLogo}
            promoCodeLogoDisplay={promoCodeLogoDisplay}
            promoCodeDetails={promoCodeDetails}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case PROMO_CODE_REMOVED:
        return (
          <PromoCodePopover
            promoCodeHeaderIcon={promoCodeHeaderIcon}
            promoCodeLogo={promoCodeLogo}
            promoCodeLogoDisplay={promoCodeLogoDisplay}
            promoCodeDetails={promoCodeDetails}
            dismissModal={dismissModal}
            headerColor={colorError}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case RESERVATION_NOTES:
        return (
          <NoteInput
            noteText={note}
            onSuccess={this.changeNoteField}
            onCancel={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
            saveButtonLabel={textSaveButtonLabel}
            cancelButtonLabel={textCancelButtonLabel}
            placeholder={textReservationNotesPlaceholder}
          />
        )
      case SPECIAL_ATTENTION_MESSAGE:
        return (
          <PolicyPopover
            policyHeader={specialAttentionMessageHeader}
            policyDetails={specialAttentionMessageBody}
            dismissModal={dismissModal}
            headerColor={colorPrimary}
            headerTextColor={fontsColorButton}
            textColor={fontsColorSecondary}
          />
        )
      case SPINNER:
        return <Spinner mediaUrl={mediaUrl} label={modalMessage} />
      case ERROR_DISPLAY:
        return (
          <ErrorDisplay
            errorMessage={modalMessage}
            actionText={actionText}
            dismissModal={() => dismissModal() || actionCallback()}
            errorColor={colorError}
          />
        )
      case UI_DISABLED:
      default:
        return <div />
    }
  }

  _handleTabKeyDown = event => {
    if (event.keyCode !== 9 || !this.modalBody || !this.modalBody.type.firstElementId || !this.modalBody.type.lastElementId) {
      return
    }
    const firstFocusableEl = document.getElementById(this.modalBody.type.firstElementId)
    const lastFocusableEl = document.getElementById(this.modalBody.type.lastElementId)
    if (event.shiftKey) {
      if (document.activeElement === firstFocusableEl) {
        event.preventDefault()
        lastFocusableEl.focus()
      }
    } else if (document.activeElement === lastFocusableEl) {
      event.preventDefault()
      firstFocusableEl.focus()
    }
  }

  render() {
    const { modalType, dismissModal, colorModalBackground, isMobile } = this.props
    const modalBody = this._setModalBody(modalType)
    if (!this._shouldShowModal(modalType)) {
      return null
    }

    const dismissModalProp = _.includes([modalTypes.SPINNER, modalTypes.UI_DISABLED], modalType) ? () => {} : dismissModal

    return (
      <Modal
        body={this.modalBody}
        modalType={_.startCase(_.capitalize(modalType))}
        dismissModal={dismissModalProp}
        backgroundColor={colorModalBackground}
        overflowX="hidden"
        customStyles={{
          top: '100px',
          transform: 'translate(-50%, 0)',
          maxHeight: isMobile ? '65vh' : '80vh',
        }}
      />
    )
  }
}

const mapStateToProps = state => {
  const partySize = state.search.get('partySize')
  const dietaryRestrictionsId = state.entities.tags.getIn(['clientTagGroups', 'dietaryPreference'])
  const dietaryRestrictionsGuestId = state.entities.tags.getIn(['reservationTagGroups', 'dietaryPreference'])
  const specialOccasionsId = state.entities.tags.getIn(['reservationTagGroups', 'specialOccasion'])

  const dietTagGroups = []
  if (dietaryRestrictionsId) {
    dietTagGroups.push(state.entities.tags.getIn(['tagGroups', dietaryRestrictionsId]).toJS())
  }
  if (partySize > 1 && dietaryRestrictionsGuestId) {
    dietTagGroups.push(state.entities.tags.getIn(['tagGroups', dietaryRestrictionsGuestId]).toJS())
  }

  const occasionTagGroup = []
  if (specialOccasionsId) {
    occasionTagGroup.push(state.entities.tags.getIn(['tagGroups', specialOccasionsId]).toJS())
  }

  const timeDisplayFormat = state.venueInfo.locale === 'en_GB' ? 'HH:mm' : 'h:mm a'
  const selectedTimeFormatted = state.search.get('selectedTimeSlot') ? state.search.get('selectedTimeSlot').format(timeDisplayFormat) : null

  const selectedTime = state.search.get('selectedTimeSlot')
    ? state.search.get('selectedTimeSlot').format('hh:mm A').replace(/^0+/, '')
    : null
  const selectedDate = state.search.get('selectedTimeSlot') ? state.search.get('selectedTimeSlot').format('YYYY-MM-DD') : null
  const accessPersistentId = state.search.get('accessPersistentId') ? state.search.get('accessPersistentId') : null

  const selectedVenue = state.app.isWaitlistWidget ? state.venueInfo.urlKey : state.searchResults.get('timeSlotVenue')
  const selectedAvailabilityImmutable =
    selectedDate && state.availabilityLookup.getIn([selectedVenue, selectedDate, selectedTime, accessPersistentId])
  const selectedAvailability = selectedAvailabilityImmutable && selectedAvailabilityImmutable.toJS()

  const languageStrings = selectLanguageStrings(state)

  const agreeToWaitlistPolicy = languageStrings.waitlistTextAgreeToWaitlistPolicyModal

  const promoCode = state.formFields.get('promoCodeInfo')
  const promoCodeHeaderIcon = promoCode ? 'fa fa-money-vms-line' : 'fa fa-remove-money-line'
  const promoCodeLogo = promoCode
    ? [state.widgetSettings.mediaUrl, 'images/widget/', CreditCardImages[camelCaseString(promoCode.get('creditCardType'))]].join('')
    : ''
  const promoCodeLogoDisplay = promoCode ? 'inline-block' : 'none'
  const promoCodeDetails = promoCode
    ? promoCode.get('promoDescription')
    : [
        'You are no longer using ',
        state.formFields.get('lastApplicableCC'),
        '. You can still book but you will not receive the promotional discount.',
      ].join('')

  const tagTranslations = !_.isEmpty(state.languages.selectedLanguage)
    ? state.languages.tagLanguageStrings[state.languages.selectedLanguage]
    : {}

  const selectGratuityModalMessage = 'Please select a gratuity to continue'

  const calcLanguageStrings = selectCalculatedLanguageStrings(state)
  const venueGroupMarketingOptInPolicy = calcLanguageStrings.policyVenueGroupMarketing
  const venueSpecificMarketingOptInPolicy = calcLanguageStrings.policyVenueSpecificMarketing
  const venueSmsMarketingOptInPolicy = calcLanguageStrings.policyVenueSpecificSmsMarketing
  const reservationSmsOptInPolicy = calcLanguageStrings.policySmsOptIn

  let specialAttentionMessageHeader = ''
  let specialAttentionMessageBody = ''
  if (state.venueInfo.widgetReferrerType === 'RESERVATIONS' || state.venueInfo.widgetReferrerType === 'LANDING_PAGE') {
    specialAttentionMessageHeader = languageStrings.specialAttentionMessageHeader
    specialAttentionMessageBody = languageStrings.specialAttentionMessageBody
  } else if (state.venueInfo.widgetReferrerType === 'WAITLIST') {
    specialAttentionMessageHeader = languageStrings.waitlistWidgetSpecialAttentionLabel
    specialAttentionMessageBody = languageStrings.waitlistWidgetSpecialAttentionInfoBody
  }
  return {
    modalType: state.ui.get('displayModalType'),
    modalMessage: state.ui.get('modalMessage'),
    actionText: state.ui.get('actionText'),
    actionCallback: state.ui.get('actionCallback') || (() => null),
    dietTagGroups,
    occasionTagGroup,
    selectedAvailability,
    agreeToWaitlistPolicy,
    eventWidgetPurchasePolicy: state.availability.get('cancellationPolicy'),
    customCheckoutPolicy: calcLanguageStrings.textCustomCheckoutPolicy,
    venueGroupMarketingOptInPolicy,
    venueSpecificMarketingOptInPolicy,
    venueSmsMarketingOptInPolicy,
    reservationSmsOptInPolicy,
    currencyCode: state.venueInfo.currencyCode,
    promoCodeHeaderIcon,
    promoCodeLogo,
    promoCodeLogoDisplay,
    promoCodeDetails,
    selectGratuityModalMessage,
    note: state.formFields.get('note'),
    selectedTime,
    selectedTimeFormatted,
    mediaUrl: state.widgetSettings.mediaUrl,
    validPromoCode: state.formFields.get('validPromoCode'),
    partySize,
    cost: state.search.get('cost'),
    fees: state.search.get('fees'),
    upsellInventories: state.upsells.entities.inventories,
    // colors
    colorPrimary: state.widgetSettings.colorPrimary,
    colorModalBackground: state.widgetSettings.colorModalBackground,
    colorError: state.widgetSettings.colorError,
    fontsColorSecondary: state.widgetSettings.fontsColorSecondary,
    fontsColorButton: state.widgetSettings.fontsColorButton,
    // text
    textMarketingPolicy: languageStrings.textMarketingPolicy,
    textReservationSmsPolicy: languageStrings.textReservationSmsPolicy,
    textCancellationPolicy: languageStrings.textCancellationPolicy,
    textAdditionalSelection: languageStrings.textAdditionalSelection,
    textPerPerson: languageStrings.textPerPerson,
    textPerReservation: languageStrings.textPerReservation,
    textTagLabelYours: languageStrings.textTagLabelYours.toUpperCase(),
    textTagLabelYourGuests: languageStrings.textTagLabelYourGuests.toUpperCase(),
    textSaveButtonLabel: languageStrings.textSaveButtonLabel,
    textCancelButtonLabel: languageStrings.textCancelButtonLabel,
    textSelectButtonLabel: languageStrings.textSelectButtonLabel,
    textReservationNotesPlaceholder: languageStrings.textReservationNotesPlaceholder,
    textCustomCheckoutPolicyHeader: languageStrings.textCustomCheckoutPolicyHeader,
    specialAttentionMessageHeader,
    specialAttentionMessageBody,
    tagTranslations,
    isMobile: state.app.isMobile,
    waitlistAgreePolicyModalTitle: languageStrings.waitlistAgreePolicyModalTitle,
  }
}

const mapDispatchToProps = dispatch => ({
  dismissModal: () => {
    dispatch(dismissModal())
  },
  selectHandler: () => {
    dispatch(dismissModal())
    dispatch(confirmTimeSlot())
  },
  saveTagGroups: tagGroups => {
    _.each(tagGroups, (tagGroup, groupId) => dispatch(saveTagGroup(tagGroup, groupId)))
  },
  changeFormField: (field, changeTo) => {
    dispatch(changeFormField(field, changeTo))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ModalManager))
